// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "24.5.0",
  "buildNumber": "19913",
  "gitSha": "8f7bf5297c90c909e42d030baad54a1d32701c65",
  "fullVersion": "24.5.0-19913",
  "formattedVersion": "24.5.0 (19913-8f7bf5297c90c909e42d030baad54a1d32701c65)",
  "copyrightYear": "2024"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/24.5.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/24.5.0/${stripLeadingPrefix(suffix, '#')}`
}
    
